//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import imgdown from "@/assets/icon-down.png";
import imgdate from "@/assets/icon-date.png";
import female from "@/assets/female.png";
import male from "@/assets/male.png";
import api from "./networking/api";
import common from "./common/common";
import "./common/previewImage";
import { LocalStorage } from "quasar";
export default {
  name: "figureSignStatisticsDetail",
  data() {
    return {
      female,
      male,
      imgdown,
      imgdate,
      storeOption: [],
      roleList: [],
      storeListLoading: false,
      storesOptions: [],
      curStore: "",
      storeCode: "",
      storeName: "",
      days: 30,
      day: 1,
      minDate: new Date(2023, 6, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      currentFormatDate: this.$moment(new Date()).format("yyyy-MM"),
      showPopover: false,
      dailyList: [],
    };
  },
  created() {
    console.log(this.$route)
    this.storeCode = this.$route.query.storeId
    this.days = this.$moment(this.$route.query.date, "YYYY-MM-DD").daysInMonth();
    this.day =  this.$moment(this.$route.query.date, "YYYY-MM-DD").get('date') - 1
    window.document.title = "打卡统计";
    this.requestJsSdkConfig()
    this.getDailyList()
  },
  methods: {
    requestJsSdkConfig: function () {
      let that = this;
      let agentId = LocalStorage.getItem("agentId");
      let corpId = LocalStorage.getItem("corpId");
      this.axios
        .get(api.getJsSdkConfig, {
          params: {
            agentId,
            corpId,
            url: encodeURIComponent(window.location.href.split("#")),
          },
        })
        .then(function (response) {
          if (common.success(response)) {
            let data = response.data.data;
            that.wxConfigData = {
              nonceStr: data.nonceStr,
              timestamp: data.timestamp,
              signature: data.signature,
              appId: data.appId,
            };
            that.wxConfig(that.wxConfigData);
          } else {
            alert("获取JsSdkConfig失败：" + response.data.reason);
          }
        })
        .catch(function (error) {
          alert("获取JsSdkConfig失败：" + error);
        })
        .then(function () {});
    },
    wxConfig: function (data) {
      wx.config({
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，企业微信的corpID
        timestamp: data.timestamp, //  必填，生成签名的时间戳
        nonceStr: data.nonceStr, //  必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: [
          "previewImage",
        ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      });

      wx.ready(function () {
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      });

      wx.error(function (res) {
        alert(JSON.stringify(res));
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });
    },
    isMobile(){
     return /Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent)
    },
    previewImage: function (url) {
      if (
       this.isMobile()
      ) {
        wx.previewImage({
          current: url, // 当前显示图片的http链接
          urls: [url], // 需要预览的图片http链接列表
        });
      } else {
        window.previewImage.start({
          current: url, // 当前显示图片的http链接
          urls: [url], // 需要预览的图片http链接列表
        });
        
      }
    },
    toggleLike(id,assessState){
      const that = this;
      // assessState默认0 喜欢1 不喜欢 2
      let postData = {
        id,
        assessState
      }
      this.axios
        .post("/consumer/store/figuresign/modifyStoreFigureSignState", postData)
        .then((res) => {
          console.log(res);
          that.getDailyList()
        });
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      }
      if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    chooseDate(e) {
      console.log(e);
      this.currentDate = e;
      this.currentFormatDate = this.$moment(e).format("yyyy-MM");
      this.days = this.$moment(e, "YYYY-MM").daysInMonth();
      this.day = 0;
      this.getDailyList();
      this.showPopover = false;
    },
    filterFn(val, update, abort) {
      update(() => {
        const needle = val.toUpperCase();
        this.storesOptions = this.storeOption.filter(function (v) {
          let result = v.storeId.toUpperCase().indexOf(needle) > -1;
          let result1 = v.title.indexOf(needle) > -1;
          if (result || result1) {
            return true;
          }
          return false;
        });
      });
    },
    onClickTab(val) {
      console.log(val);
      this.day = val;
      this.getDailyList();
    },
    getDailyList() {
      const that = this;
      // assessState默认0 喜欢1 不喜欢 2
      let postData = {
        queryMapObject: {
          storeId:this.storeCode,
          startTime:this.$moment(this.currentFormatDate + '-' + this.day,"yyyy-MM-DD")
          .add(1, 'days')
          .format("yyyy-MM-DD") + ' 00:00:00',
          endTime:this.$moment(this.currentFormatDate + '-' + this.day,"yyyy-MM-DD")
          .add(1, 'days')
          .format("yyyy-MM-DD") + ' 23:59:59',
        }
      }
      this.axios
        .post("/consumer/store/figuresign/getStoreFigureSignManageList", postData)
        .then((res) => {
          console.log(res);
          if (res.data && res.data.data) {
            that.dailyList = res.data.data
          }else{
            that.dailyList = []
          }
        });
    },
  },
};
