const res = {
    success: function(response) {
        if (response.data.code == 200 || response.data.code == 0 ) {
            return true;
        }
        return false;
    },

    config: {
        agentId: '1000058',
        corpId:'wxee07e0adf516838a',
        mapKey: 'TVCBZ-A4EC3-RIJ3Q-3ZEVT-DSUF5-33FAE'
    },
    formatBrand: function(brand) {
        switch (brand) {
            case 'ZBXN':
                return '报喜鸟';
            case 'ZCAM':
                return '恺米切';
            case 'ZSOL':
                return '所罗定制';
            case 'ZOLT':
                return '奥莱';
            case 'ZTOM':
                return 'TOMBOLINI';
            case 'ZHAZ':
                return '哈吉斯';
            case 'ZLAF':
                return '乐飞叶';
            case 'ZKLM':
                return '柯兰美';
            case 'ZFLS':
                return '法兰诗顿';
            case 'ZSAN':
                return '圣捷罗';
            case 'ZFHX':
                return '凤凰尚品';
            case 'ZPHX':
                return '凤凰尚品';
        }
        return brand;
    }
}

export default res;
