let host = '';//'https://outletsmallapi.bxncc.cc'; //'http://192.168.33.128:8000';
let imageHost = 'https://bxn-qy-workspace.oss-cn-hangzhou.aliyuncs.com';
const api = {
    host: host,
    imageHost: imageHost,
    getUserInfo: host + '/consumer/v1/wx/cp/getUserInfo',
    getStoreFigureSignList: host + '/consumer/store/figuresign/getStoreFigureSignList',
    getStoreFigureSignDetailList: host + '/consumer/store/figuresign/getStoreFigureSignDetailList',
    uploadImage: host + '/consumer/store/figuresign/addStoreFigureSign',
    getJsSdkConfig: host + '/consumer/v1/wx/cp/getJsSdkConfig',
}

export default api;